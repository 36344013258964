/* You can add global styles to this file, and also import other style files */
@import '~angular-calendar/css/angular-calendar.css';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }

    .printable, .printable > * {display:block;}
}
